import { createSlice } from "@reduxjs/toolkit";
import { fetchDataApi, postPage, uploadFile } from "../action/page";

const initialState = {
  data: null,
  status: null,
};

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    [fetchDataApi.pending]: (state) => {
      state.data = null;
    },
    [fetchDataApi.fulfilled]: (state, payload) => {
      state.data = payload;
    },
    [fetchDataApi.rejected]: (state, payload) => {
      state.data = payload;
    },
    [postPage.pending]: (state) => {
      state.data = null;
    },
    [postPage.fulfilled]: (state, payload) => {
      state.data = payload;
    },
    [postPage.rejected]: (state, payload) => {
      state.data = payload;
    },
    [uploadFile.pending]: (state) => {
      state.data = null;
    },
    [uploadFile.fulfilled]: (state, payload) => {
      state.data = payload;
    },
    [uploadFile.rejected]: (state, payload) => {
      state.data = payload;
    },
  },
});

export default pageSlice;
