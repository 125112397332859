import { createAsyncThunk } from "@reduxjs/toolkit";
import PageService from "../store/page.service";

export const fetchDataApi = createAsyncThunk(
  "page/fetchDataApi",
  async (page) => {
    try {
      const { data } = await PageService.fetchDataApi(page);
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const postPage = createAsyncThunk("page/postPage", async (page) => {
  try {
    const data = await PageService.postPage(page);
    return data;
  } catch (error) {
    return error.response.data;
  }
});

export const uploadFile = createAsyncThunk("page/uploadFile", async (page) => {
  try {
    const { data } = await PageService.uploadFile(page);
    return data;
  } catch (error) {
    return error.response.data;
  }
});
