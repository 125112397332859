import axios from "axios";
import qs from "qs";

const fetchDataApi = async ({ pageUrl, authorization = "" }) => {
  // const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/${page}`);

  let data = null;
  if (authorization != "") {
    data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/${pageUrl}`, {
      headers: {
        Authorization: authorization,
      },
    });
  } else {
    data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/${pageUrl}`);
  }

  return data;
};

const postPage = async ({ pageUrl, pageData = null, authorization = "" }) => {
  let data = null;
  if (authorization == "") {
    data = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/${pageUrl}`,
      qs.stringify(pageData),
      {
        headers: {
          Authorization: authorization,
        },
      }
    );
  } else {
    data = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/${pageUrl}`,
      pageData ? qs.stringify(pageData) : null,
      {
        headers: {
          Authorization: authorization,
        },
      }
    );
  }

  return data;
};

const uploadFile = async ({ pageUrl, pageData }) => {
  const formData = new FormData();
  formData.append("id_user", pageData?.id_user);
  formData.append("foto_profil", pageData?.foto_profil);

  const data = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/${pageUrl}`,
    formData
  );
  return data;
};

const pageService = {
  fetchDataApi,
  postPage,
  uploadFile,
};

export default pageService;
